import React from 'react';
import star1 from "../../assets/star1.png";
import star2 from "../../assets/star2.png";
import star3 from "../../assets/star3.png";
import img4 from "../../assets/img4.png";
import styles from '../emerald/Emerald.module.scss';
import { Link } from 'react-router-dom';

const Silver = () => {
  return (
    <section className={styles.bundles}>
      <aside className={styles.bundles__left}>
        <h3>Silver (₦5,000 - ₦10,000)</h3>
        <div>
          <p>
            <img src={star1} alt="" />

            <span>
              Let us help you with your bulk inventory upload on Dukka app
            </span>
          </p>
          <p>
            <img src={star2} alt="" />

            <span>N5,000 for less than 50 product items</span>
          </p>
          <p>
            <img src={star3} alt="" />

            <span>N10,000 for 50 product items</span>
          </p>
          <p>
            <img src={star3} alt="" />

            <span>Custom pricing above 50 product items</span>
          </p>
        </div>
        <Link to={`/silver`}>
          <button>Get Started</button>
        </Link>
      </aside>
      <aside className={styles.bundles__right}>
        <img src={img4} alt="" />
      </aside>
      
    </section>
  );
}

export default Silver
