import React from "react";
import styles from "../servicepaymentlayout/RubyServicePayment.module.scss";

const SilverServicePayment = (props) => {
  const handleConfirmation = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.layout_card}>
      <div className={styles.layout_card__div}>
        <h5>
          Thank you for your interest in our service. To access the form, You
          are required to make a payment of <span>₦5,000</span> for less than 50
          product items or <span>₦10,000</span> for 51-100 product items to have
          access to this service. If you would like to proceed with the payment
          and access the form, make a transfer to the account number below
        </h5>
        <h1>5401580638</h1>
        <h2>Dukka Limited</h2>
        <h3>Providus Bank</h3>
      </div>
      <div className={styles.layout_card__btn}>
        <button
          style={{
            backgroundColor: "#E97D80",
            border: "1px solid #E97D80",
          }}
          onClick={({ values }) => props.prev(values)}
          type="button"
        >
          Back
        </button>
        <button type="submit" onClick={handleConfirmation}>
          I have sent it
        </button>
      </div>
    </div>
  );
};

export default SilverServicePayment;
