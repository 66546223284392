import * as Yup from "yup";

export const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  businessName: "",
  businessLogo: null,
  businessAddress: "",
  selectCategory: "",
  categoryChoice: "",
  businessDescription: "",
  referralCode: "",
  email: "",
  sendersName: "",
  dateOfPayment: "",
  uploadProofOfPayment: null,
};

export const stepOneValidationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
  businessName: Yup.string().required("Required"),
  businessAddress: Yup.string().required("Required"),
  selectCategory: Yup.string().required("Required"),
  categoryChoice: Yup.string().when("selectCategory", {
    is: "Others",
    then: Yup.string().required("Required"),
  }),
  referralCode: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
  businessLogo: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  businessDescription: Yup.string().required("Required"),
});

export const stepTwoValidationSchema = Yup.object({
  sendersName: Yup.string().required("Required"),
  dateOfPayment: Yup.string().required("Required"),
  uploadProofOfPayment: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 400 * 1024)
    ),
});
