import React from "react";
import { Formik, Form, Field } from "formik";
import styles from "../goldform/GoldForm2.module.scss";

const DiamondForm4 = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  const radioOptions = [
    { key: "rOption1", value: "Yes" },
    { key: "rOption2", value: "No" },
  ];

  return (
    <div className={styles.card}>
      <h2>CAC Limited registration</h2>
      <p>
        Please input the names of shareholders and their shareholding (Must be
        at least one Feel free to add more sections where more than 3)
      </p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="shareholderFirstName">
                      First name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="shareholderFirstName"
                      name="shareholderFirstName"
                    />
                    {formik.errors.shareholderFirstName &&
                    formik.touched.shareholderFirstName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.shareholderFirstName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="shareholderLastName">
                      Last name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="shareholderLastName"
                      name="shareholderLastName"
                    />
                    {formik.errors.shareholderLastName &&
                    formik.touched.shareholderLastName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.shareholderLastName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="shareholderOtherName">
                      Other name (Optional)
                    </label>
                    <Field
                      type="text"
                      id="shareholderOtherName"
                      name="shareholderOtherName"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div_address}>
                  <div>
                    <label htmlFor="shareholderAddress">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="address"
                      id="shareholderAddress"
                      name="shareholderAddress"
                    />
                    {formik.errors.shareholderAddress &&
                    formik.touched.shareholderAddress ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.shareholderAddress}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="shareholderDateOfBirth">
                    Date of birth <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="date"
                    id="shareholderDateOfBirth"
                    name="shareholderDateOfBirth"
                  />
                  {formik.errors.shareholderDateOfBirth &&
                  formik.touched.shareholderDateOfBirth ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.shareholderDateOfBirth}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="shareholderPhoneNumber">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="tel"
                    id="shareholderPhoneNumber"
                    name="shareholderPhoneNumber"
                  />
                  {formik.errors.shareholderPhoneNumber &&
                  formik.touched.shareholderPhoneNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.shareholderPhoneNumber}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="percentage">
                    Shareholding Percentage of share capital{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="percentage" name="percentage" />
                  {formik.errors.percentage && formik.touched.percentage ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.percentage}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="ninNumber">
                    NIN number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="ninNumber" name="ninNumber" />
                  {formik.errors.ninNumber && formik.touched.ninNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.ninNumber}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label className={styles.radio_label} htmlFor="radioOption">
                    Will you be a director?
                  </label>
                  <div className={styles.card_form_section_div__control__radio_input}>
                    <Field name="radioOption" value={radioOptions.key}>
                      {({ field }) => {
                        return radioOptions.map((option) => {
                          return (
                            <div
                              className={
                                styles.card_form_section_div__control__radio_input__options
                              }
                              key={option.value}
                            >
                              <input
                                type="radio"
                                id={option.value}
                                {...field}
                                value={option.value}
                                checked={field.value === option.value}
                              />
                              <label htmlFor={option.value}>
                                {option.value}
                              </label>
                            </div>
                          );
                        });
                      }}
                    </Field>
                  </div>
                </div>
                {formik.errors.radioOption && formik.touched.radioOption ? (
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.radioOption}
                  </p>
                ) : null}
              </div>

              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DiamondForm4;
