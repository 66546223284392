import React from "react";
import styles from "../success/Success.module.scss";
import rocket from "../../assets/rocket.png";

const Success = () => {
  return (
    <section className={styles.layout}>
      <div className={styles.layout_card}>
        <>
          <img src={rocket} alt="" />
        </>
        <div>
          <h2>Great!</h2>
          <p>
            <span>Thank you for your submission.</span>
            <span>
              Kindly expect confirmation feedback from us within the next
              24-72hrs. Need help? Call 01-887-8000 or use the Live-chat option
              to contact us.
            </span>
          </p>
        </div>
      </div>
      <div className={styles.layout_footnote}>
        <p>
          Need help? Email us at{" "}
          <a
            style={{ textDecoration: "none", color: "black", fontWeight: 700 }}
            href="mailto:launchpad@dukka.com"
            target="_blank"
            rel="noreferrer"
          >
            launchpad@dukka.com
          </a>
        </p>
      </div>
    </section>
  );
};

export default Success;
