import React, { useState, useContext } from "react";
import styles from "../questions/Question.module.scss";
import { BsPlus } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { DataContext } from "../../context/Context";

const Question = () => {
  const [selectedId, setSelectedId] = useState(null);
  const { data } = useContext(DataContext);

  const toggle = (id) => {
    if (selectedId === id) {
      setSelectedId(null);
      return false;
    }
    setSelectedId(id);
  };

  return (
    <section className={styles.questions}>
      {data &&
        data.map((element, id) => (
          <div key={element.id} className={styles.questions_card}>
            <div
              className={styles.questions_card__content}
              onClick={() => toggle(id)}
            >
              <p>{element.question}</p>
              <span>{selectedId === id ? <IoIosClose /> : <BsPlus />}</span>
            </div>
            {selectedId === id ? (
              <div className={styles.questions_card__answer}>
                <p>{element.answer}</p>
              </div>
            ) : null}
          </div>
        ))}
    </section>
  );
};

export default Question;
