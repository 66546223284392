// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*************** Font family *************************/\n/************* Colors ******************/\n/*************** Breakpoints *************************/\n/****************** Mixins **********************/\n* {\n  margin: 0px;\n  padding: 0px;\n  box-sizing: border-box;\n}", "",{"version":3,"sources":["webpack://./src/styles/variables.scss","webpack://./src/styles/styles.scss"],"names":[],"mappings":"AAEA,sDAAA;AAKA,wCAAA;AASA,sDAAA;AAQA,iDAAA;ACtBA;EACI,WAAA;EACA,YAAA;EACA,sBAAA;AAIJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');\n\n/*************** Font family *************************/\n\n$fontFamily: 'Poppins', sans-serif;\n\n\n/************* Colors ******************/\n\n$apricot: #E97D80;\n$black: #000000;\n$white: #ffffff;\n$grunge: #231F20;\n$backgroundColor: #F5F5F5;\n$inputField: #D9DBDC;\n\n/*************** Breakpoints *************************/\n\n$md: 768px;\n$lg: 1024px;\n$xl: 1200px;\n$xxl: 1450px;\n\n\n/****************** Mixins **********************/\n\n@mixin flexCenter($direction) {\n    display: flex;\n    flex-direction: $direction;\n    justify-content: center;\n    align-items: center;\n}\n\n@mixin btn {\n    background-color: $grunge;\n    border-radius: 10px;\n    border: 1px solid $grunge;\n    color: $white;\n    padding: 10px 20px;\n    @include flexCenter(row);\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 30px;\n    cursor: pointer;\n}\n\n@mixin btn2 {\n    background-color: $grunge;\n    border-radius: 6px;\n    border: 1px solid $grunge;\n    color: $white;\n    padding: 10px 26px;\n    font-size: 12px;\n    cursor: pointer;\n    line-height: 20px;\n}","@import './variables.scss';\n\n* {\n    margin: 0px;\n    padding: 0px;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
