import React from "react";
import { Link } from "react-router-dom";
import styles from "../404/NotFound.module.scss";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <section className={styles.error}>
        <div className={styles.error_left}>
          <p>404</p>
        </div>

        <div className={styles.error_right}>
          <h1> Page Not Found! </h1>
          <p>
            We're sorry, but we can't find the page you were looking for. It's
            probably some thing we've done wrong but now we know about it and
            we'll try to fix it. In the meantime, use the link below to return
            to the Home page;
          </p>
          <ul>
            <li>
              <Link to="/">Go to Homepage</Link>
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NotFound;
