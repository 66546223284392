import React from "react";
import Bundles from "../bundles/Bundles";
import ring from "../../assets/ring.png";
import styles from "../services/Services.module.scss";

const Services = () => {
  return (
    <section id="services" className={styles.services}>
      <div className={styles.services_div}>
        <h2 className={styles.services_div__heading}>
          Get more
          <span className={styles.services_div__heading__span}>
            <img src={ring} alt="" />
            <span> value </span>
          </span>
          with our bundled services
        </h2>
        <p>
          Enjoy more when you pay less. Choose a bundled service that suits your
          business.
        </p>
      </div>

      <div>
        <Bundles />
      </div>
    </section>
  );
};

export default Services;
