import * as Yup from "yup";

export const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  businessName: "",
  address: "",
  facebookHandle: "",
  selectCategory: "",
  categoryChoice: "",
  email: "",
  instagramHandle: "",
  referralCode: "",
  businessDescription: "",
  "product.image": null,
  "product.description": "",
  "product.price": 0,
  "product.image1": null,
  "product.price1": 0,
  "product.description1": "",
  "product.image2": null,
  "product.price2": 0,
  "product.description2": "",
  "product.image3": null,
  "product.price3": 0,
  "product.description3": "",
  sendersName: "",
  dateOfPayment: "",
  uploadProofOfPayment: "",
  phoneNumber2: "",
  businessNames: ["", "", ""],
  businessAddress: "",
  businessObjects: ["", "", "", ""],
  businessEmail: "",
  proprietorFirstName: "",
  proprietorLastName: "",
  proprietorOtherName: "",
  proprietorDateOfBirth: "",
  proprietorAddress: "",
  proprietorPhoneNumber: "",
  dateOfCommencement: "",
  ninCard: "",
  passportPhotograph: "",
  signature: "",
};

export const stepOneValidationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
  businessName: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  facebookHandle: Yup.string(),
  selectCategory: Yup.string().required("Required"),
  categoryChoice: Yup.string().when("selectCategory", {
    is: "Others",
    then: Yup.string().required("Required"),
  }),
  email: Yup.string().email("Invalid email").required("Required"),
  instagramHandle: Yup.string(),
  referralCode: Yup.string(),
  businessDescription: Yup.string().required("Required"),
});

export const stepTwoValidationSchema = Yup.object({
  "product.image": Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  "product.price": Yup.string().required("Required"),
  "product.description": Yup.string().required("Required"),
  "product.image1": Yup.mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  "product.price1": Yup.string(),
  "product.description1": Yup.string(),
  "product.image2": Yup.mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  "product.price2": Yup.string(),
  "product.description2": Yup.string(),
  "product.image3": Yup.mixed()
    .nullable()
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  "product.price3": Yup.string(),
  "product.description3": Yup.string(),
});

export const stepThreeValidationSchema = Yup.object({
  phoneNumber2: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
  businessAddress: Yup.string().required("Required"),
  businessNames: Yup.array()
    .of(Yup.string())
    .required("Required")
    .min(2, "Kindly provide 2 names"),
  businessObjects: Yup.array()
    .of(Yup.string())
    .required("Required")
    .min(2, "Kindly provide at least 2 objects"),
  referralCode: Yup.string(),
  businessEmail: Yup.string().email("Invalid email").required("Required"),
});

export const stepFourValidationSchema = Yup.object({
  proprietorFirstName: Yup.string().required("Required"),
  proprietorLastName: Yup.string().required("Required"),
  proprietorOtherName: Yup.string(),
  proprietorDateOfBirth: Yup.string().required("Required"),
  proprietorAddress: Yup.string().required("Required"),
  proprietorPhoneNumber: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
});

export const stepFiveValidationSchema = Yup.object({
  dateOfCommencement: Yup.string().required("Required"),
  ninCard: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  passportPhotograph: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  signature: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
});

export const stepSixValidationSchema = Yup.object({
  sendersName: Yup.string().required("Required"),
  dateOfPayment: Yup.string().required("Required"),
  uploadProofOfPayment: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
});