import * as Yup from "yup";

export const initialValues = {
    firstName: "",
    lastName: "",
    otherName: "",
    address: "",
    dateOfBirth: "",
    phoneNumber: "",
    businessNames: [ "", "", ""],
    businessAddress: "",
    businessObjects: ["", "", "", ""],
    referralCode: "",
    email: "",
    dateOfCommencement: "",
    ninCard: null,
    passportPhotograph: null,
    signature: null,
    nameOnNin: null,
    sendersName: "",
    dateOfPayment: "",
    uploadProofOfPayment: null,
};

export const stepOneValidationSchema = Yup.object({
  phoneNumber: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
  businessNames: Yup.array()
    .of(Yup.string())
    .required("Required")
    .min(2, "Kindly provide 2 names"),
  businessAddress: Yup.string().required("Required"),
  businessObjects: Yup.array()
    .of(Yup.string())
    .required("Required")
    .min(2, "Kindly provide at least 2 objects"),
  referralCode: Yup.string(),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const stepTwoValidationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  otherName: Yup.string(),
  dateOfBirth: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  phoneNumber1: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Invalid phone number"
    ),
});

export const stepThreeValidationSchema = Yup.object({
  dateOfCommencement: Yup.string().required("Required"),
  nameOnNin: Yup.string().required("Required"),
  ninCard: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  passportPhotograph: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
  signature: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
});

export const stepFourValidationSchema = Yup.object({
  sendersName: Yup.string().required("Required"),
  dateOfPayment: Yup.string().required("Required"),
  uploadProofOfPayment: Yup.mixed()
    .nullable()
    .required("Required")
    .test(
      "FILE_SIZE",
      "File Size is too large",
      (value) => !value || (value && value.size <= 160 * 1024)
    ),
});