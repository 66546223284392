import React, { useContext } from 'react';
import { Formik, Form, Field } from "formik";
import styles from "../RubyForm2.module.scss";
import { DataContext } from "../../../context/Context";

const PlatinumForm2 = (props) => {
  const { Preview } = useContext(DataContext);

    const handleSubmit = (values) => {
      props.next(values);
    };

  return (
    <div className={styles.card}>
      <h2>Platinum</h2>
      <p>
        We just need a little more information from you - can you please input
        your catalog for us
      </p>
      <Formik
        initialValues={props.data}
        validationSchema={props.validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.image']">
                    Attach image <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    id="['product.image']"
                    name="['product.image']"
                    accept="image/jpeg,image/png,image/*"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue(
                        "['product.image']",
                        e.currentTarget.files[0]
                      )
                    }
                    type="file"
                  />
                  {formik.errors["product.image"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.image"]}
                    </p>
                  ) : null}
                  {formik.values["product.image"] && (
                    <Preview file={formik.values["product.image"]} />
                  )}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.price']">
                    Product price <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    onChange={(e) => {
                      formik.handleChange("['product.price']");
                      const toNum = Number(
                        e.target.value.replace(/[^0-9]+/g, "")
                      );
                      formik.setFieldValue("['product.price']", toNum);
                    }}
                    id="['product.price']"
                    name="['product.price']"
                  />
                  {formik.errors["product.price"] &&
                  formik.touched["product.price"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.price"]}
                    </p>
                  ) : null}
                </div>

                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.description']">
                    Description <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="['product.description']"
                    name="['product.description']"
                  />
                  {formik.errors["product.description"] &&
                  formik.touched["product.description"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.description"]}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.image1']">Attach image</label>
                  <input
                    id="['product.image1']"
                    name="['product.image1']"
                    accept="image/jpeg,image/png, image/*"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue(
                        "['product.image1']",
                        e.currentTarget.files[0]
                      )
                    }
                    type="file"
                  />
                  {formik.errors["product.image1"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.image1"]}
                    </p>
                  ) : null}
                  {formik.values["product.image1"] && (
                    <Preview file={formik.values["product.image1"]} />
                  )}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.price1']">Product price</label>
                  <Field
                    onChange={(e) => {
                      formik.handleChange("['product.price1']");
                      const toNum = Number(
                        e.target.value.replace(/[^0-9]+/g, "")
                      );
                      formik.setFieldValue("['product.price1']", toNum);
                    }}
                    id="['product.price1']"
                    name="['product.price1']"
                  />
                  {formik.errors["product.price1"] &&
                  formik.touched["product.price1"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.price1"]}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.description1']">Description</label>
                  <Field
                    type="text"
                    id="['product.description1']"
                    name="['product.description'1]"
                  />
                  {formik.errors["product.description1"] &&
                  formik.touched["product.description1"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.description1"]}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.image2']">Attach image</label>
                  <input
                    id="['product.image2']"
                    name="['product.image2']"
                    accept="image/jpeg,image/png, image/*"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue(
                        "['product.image2']",
                        e.currentTarget.files[0]
                      )
                    }
                    type="file"
                  />
                  {formik.errors["product.image2"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.image2"]}
                    </p>
                  ) : null}
                  {formik.values["product.image2"] && (
                    <Preview file={formik.values["product.image2"]} />
                  )}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.price2']">Product price</label>
                  <Field
                    onChange={(e) => {
                      formik.handleChange("['product.price2']");
                      const toNum = Number(
                        e.target.value.replace(/[^0-9]+/g, "")
                      );
                      formik.setFieldValue("['product.price2']", toNum);
                    }}
                    id="['product.price2']"
                    name="['product.price2']"
                  />
                  {formik.errors["product.price2"] &&
                  formik.touched["product.price2"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.price2"]}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.description2']">Description</label>
                  <Field
                    type="text"
                    id="['product.description2']"
                    name="['product.description2']"
                  />
                  {formik.errors["product.description2"] &&
                  formik.touched["product.description2"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.description2"]}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.image3']">Attach image</label>
                  <input
                    id="['product.image3']"
                    name="['product.image3']"
                    accept="image/jpeg,image/png, image/*"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue(
                        "['product.image3']",
                        e.currentTarget.files[0]
                      )
                    }
                    type="file"
                  />
                  {formik.errors["product.image3"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.image3"]}
                    </p>
                  ) : null}
                  {formik.values["product.image3"] && (
                    <Preview file={formik.values["product.image3"]} />
                  )}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.price3']">Product price</label>
                  <Field
                    onChange={(e) => {
                      formik.handleChange("['product.price3']");
                      const toNum = Number(
                        e.target.value.replace(/[^0-9]+/g, "")
                      );
                      formik.setFieldValue("['product.price3']", toNum);
                    }}
                    id="['product.price3']"
                    name="['product.price3']"
                  />
                  {formik.errors["product.price3"] &&
                  formik.touched["product.price3"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.price3"]}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="['product.description3']">Description</label>
                  <Field
                    type="text"
                    id="['product.description3']"
                    name="['product.description3']"
                  />
                  {formik.errors["product.description3"] &&
                  formik.touched["product.description3"] ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors["product.description3"]}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PlatinumForm2
