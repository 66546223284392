import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./components/home/Home";
import Error from "./modules/error/Error";
import EmeraldRequirements from "./pages/requirements/EmeraldRequirements";
import RubyRequirements from "./pages/requirements/RubyRequirements";
import BronzeRequirements from "./pages/requirements/BronzeRequirements";
import SilverRequirements from "./pages/requirements/SilverRequirements";
import PearlRequirements from "./pages/requirements/PearlRequirements";
import GoldRequirements from "./pages/requirements/GoldRequirements";
import PlatinumRequirements from "./pages/requirements/PlatinumRequirements";
import DiamondRequirements from "./pages/requirements/DiamondRequirements";
import EmeraldForm from "./pages/form/emeraldform/EmeraldForm";
import RubyForm from "./pages/form/rubyform/RubyForm";
import BronzeForm from "./pages/form/bronzeform/BronzeForm";
import SilverForm from "./pages/form/silverform/SilverForm";
import PlatinumForm from "./pages/form/platinumform/PlatinumForm";
import GoldForm from "./pages/form/goldform/GoldForm";
import DiamondForm from "./pages/form/diamondform/DiamondForm";
import PearlForm from "./pages/form/pearlform/PearlForm";
import Context from "./context/Context";
import PaymentConfirmation from "./components/paymentconfirmation/PaymentConfirmation";
import Success from "./modules/success/Success";
import NotFound from "./pages/404/NotFound";

function App() {
  return (
    <Context>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/emerald" element={<EmeraldRequirements />} />
          <Route path="/ruby" element={<RubyRequirements />} />
          <Route path="/bronze" element={<BronzeRequirements />} />
          <Route path="/silver" element={<SilverRequirements />} />
          <Route path="/pearl" element={<PearlRequirements />} />
          <Route path="/gold" element={<GoldRequirements />} />
          <Route path="/platinum" element={<PlatinumRequirements />} />
          <Route path="/diamond" element={<DiamondRequirements />} />
          <Route exact path="/form/Emerald" element={<EmeraldForm />} />
          <Route exact path="/form/Ruby" element={<RubyForm />} />
          <Route exact path="/form/Bronze" element={<BronzeForm />} />
          <Route exact path="/form/Silver" element={<SilverForm />} />
          <Route exact path="/form/Pearl" element={<PearlForm />} />
          <Route exact path="/form/Gold" element={<GoldForm />} />
          <Route exact path="/form/Platinum" element={<PlatinumForm />} />
          <Route exact path="/form/Diamond" element={<DiamondForm />} />
          <Route path="/form/confirmation" element={<PaymentConfirmation />} />
          <Route path="/form/success" element={<Success />} />
          <Route path="/form/error" element={<Error />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Context>
  );
}

export default App;
