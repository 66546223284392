import React from "react";
import { Formik, Form, Field } from "formik";
import styles from "../FormStyle.module.scss";

const RubyForm1 = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.card}>
      <h2>Ruby</h2>
      <p>Complete this form to continue your registration.</p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="firstName">
                    First name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="firstName" name="firstName" />
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.firstName}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="lastName">
                    Last name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="lastName" name="lastName" />
                  {formik.errors.lastName && formik.touched.lastName ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.lastName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="phoneNumber">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="tel" id="phoneNumber" name="phoneNumber" />
                  {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.phoneNumber}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="businessName">
                    Business name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="businessName" name="businessName" />
                  {formik.errors.businessName && formik.touched.businessName ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessName}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="instagramHandle">Instagram handle</label>
                  <Field
                    type="text"
                    id="instagramHandle"
                    name="instagramHandle"
                  />
                  {formik.errors.instagramHandle &&
                  formik.touched.instagramHandle ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.instagramHandle}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="facebookHandle">Facebook handle</label>
                  <Field
                    type="text"
                    id="facebookHandle"
                    name="facebookHandle"
                  />
                  {formik.errors.facebookHandle &&
                  formik.touched.facebookHandle ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.facebookHandle}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="email" id="email" name="email" />
                  {formik.errors.email && formik.touched.email ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>

                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="referralCode">Referral code</label>
                  <Field type="text" id="referralCode" name="referralCode" />
                  {formik.errors.referralCode && formik.touched.referralCode ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.referralCode}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="businessDescription">
                    Business description <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="businessDescription"
                    name="businessDescription"
                  />
                  {formik.errors.businessDescription &&
                  formik.touched.businessDescription ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessDescription}
                    </p>
                  ) : null}
                </div>
              </div>
              <button
                style={{
                  opacity:
                    Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                }}
                disabled={
                  !formik.isValid && Object.entries(formik.errors).length === 0
                }
                type="submit"
              >
                Next
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RubyForm1;
