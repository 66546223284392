import React from 'react';
import { Formik, Form, Field } from "formik";
import styles from "../goldform/GoldForm2.module.scss";

const PlatinumForm4 = (props) => {
    const handleSubmit = (values) => {
      props.next(values);
    };

  return (
    <div className={styles.card}>
      <h2>CAC Business name registration</h2>
      <p>
        We just need a little more information from you - can you please input
        business Proprietor's information
      </p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="proprietorFirstName">
                      First name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="proprietorFirstName"
                      name="proprietorFirstName"
                    />
                    {formik.errors.proprietorFirstName &&
                    formik.touched.proprietorFirstName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.proprietorFirstName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="proprietorLastName">
                      Last name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="proprietorLastName"
                      name="proprietorLastName"
                    />
                    {formik.errors.proprietorLastName &&
                    formik.touched.proprietorLastName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.proprietorLastName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="proprietorOtherName">
                      Other name (Optional)
                    </label>
                    <Field
                      type="text"
                      id="proprietorOtherName"
                      name="proprietorOtherName"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div_address}>
                  <div>
                    <label htmlFor="proprietorAddress">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="address"
                      id="proprietorAddress"
                      name="proprietorAddress"
                    />
                    {formik.errors.proprietorAddress &&
                    formik.touched.proprietorAddress ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.proprietorAddress}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="proprietorDateOfBirth">
                    Date of birth <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="date"
                    id="proprietorDateOfBirth"
                    name="proprietorDateOfBirth"
                  />
                  {formik.errors.proprietorDateOfBirth &&
                  formik.touched.proprietorDateOfBirth ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.proprietorDateOfBirth}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="proprietorPhoneNumber">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="tel"
                    id="proprietorPhoneNumber"
                    name="proprietorPhoneNumber"
                  />
                  {formik.errors.proprietorPhoneNumber &&
                  formik.touched.proprietorPhoneNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.proprietorPhoneNumber}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PlatinumForm4
