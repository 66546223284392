import React from "react";
import logo2 from "../../assets/logo2.png";
import styles from "../footer/Footer.module.scss";
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";

const Footer = () => {
  return (
    <section className={styles.footer}>
      <div className={styles.footer_div}>
        <aside className={styles.footer_links}>
          <>
            <a href="https://dukka.com/" rel="noreferrer">
              <img
                className={styles.footer_links__logo}
                src={logo2}
                alt="dukka logo"
              />
            </a>
          </>

          <div className={styles.footer_links_icon}>
            <a href="https://twitter.com/trustdukka/" rel="noreferrer">
              <AiOutlineTwitter />
            </a>
            <a href="https://www.instagram.com/trustdukka/" rel="noreferrer">
              <AiOutlineInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/dukka/mycompany/verification/"
              rel="noreferrer"
            >
              <AiFillLinkedin />
            </a>
          </div>
        </aside>

        <aside className={styles.footer_note}>
          <div className={styles.footer_note__div}>
            <div>
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="https://dukka.com/about" rel="noreferrer">
                    About
                  </a>
                </li>
                <li>
                  <a href="https://dukka.com/contact/" rel="noreferrer">
                    Contact us
                  </a>
                </li>
                <li>
                  <a href="https://dukka.com/campus/" rel="noreferrer">
                    Ambassadors
                  </a>
                </li>
                <li>
                  <a href="https://dukka.com/partners/" rel="noreferrer">
                    Partners
                  </a>
                </li>
                <li>
                  <a href="https://dukka.com/career/" rel="noreferrer">
                    Career
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4>Legal</h4>
              <ul>
                <li>
                  <a href="https://dukka.com/terms" rel="noreferrer">
                    Terms
                  </a>
                </li>
                <li>
                  <a
                    href="https://dukka.com/balance-terms-and-conditions/"
                    rel="noreferrer"
                  >
                    Balance T & C
                  </a>
                </li>
                <li>
                  <a
                    href="https://dukka.com/terminal-terms-and-conditions/"
                    rel="noreferrer"
                  >
                    Terminals T & C
                  </a>
                </li>
                <li>
                  <a href="https://dukka.com/privacy" rel="noreferrer">
                    Privacy
                  </a>
                </li>
                <li>
                  <a href="https://dukka.com/product-manual" rel="noreferrer">
                    Product Manual
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </div>
      <p>
        Copyright © {new Date().getFullYear()} Dukka, Inc. All Rights Reserved. {" "} ™
      </p>
    </section>
  );
};

export default Footer;
