import React from "react";
import { Formik, Form, Field } from "formik";
import styles from "../FormStyle.module.scss";

const SilverForm1 = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  const radioOptions = [
    { value: "1-40", key: "first" },
    { value: "40-50", key: "second" },
  ];

  const selectOptions = [
    { key: "rOption1", value: "" },
    { key: "rOption2", value: "Computers" },
    { key: "rOption3", value: "Online Store" },
    { key: "rOption4", value: "Beauty & Personal care" },
    { key: "rOption5", value: "Food & Drinks" },
    { key: "rOption6", value: "Cafes & Restaurants" },
    { key: "rOption7", value: "Automotives" },
    { key: "rOption8", value: "Fashion" },
    { key: "rOption9", value: "Health & Pharmacy" },
    { key: "rOption10", value: "Arts & Crafts" },
    { key: "rOption11", value: "Baby toys/Gifts" },
    { key: "rOption12", value: "Electronics" },
    { key: "rOption13", value: "Gaming" },
    { key: "rOption14", value: "Home & Office" },
    { key: "rOption15", value: "Industrial Tools" },
    { key: "rOption16", value: "Jewellery" },
    { key: "rOption17", value: "Luggage" },
    { key: "rOption18", value: "Pets & Accessories" },
    { key: "rOption19", value: "Phone & Tablets" },
    { key: "rOption20", value: "Service & Consulting" },
    { key: "rOption21", value: "Sports & Outdoor" },
    { key: "rOption22", value: "Supermarket" },
    { key: "rOption23", value: "Others" },
  ];

  return (
    <div className={styles.card}>
      <h2>Silver</h2>
      <p>Complete this form to continue your registration.</p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="firstName">
                    First name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="firstName" name="firstName" />
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.firstName}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="lastName">
                    Last name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="lastName" name="lastName" />
                  {formik.errors.lastName && formik.touched.lastName ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.lastName}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="phoneNumber">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="tel" id="phoneNumber" name="phoneNumber" />
                  {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.phoneNumber}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="businessName">
                    Business name <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="text" id="businessName" name="businessName" />
                  {formik.errors.businessName && formik.touched.businessName ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessName}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="businessAddress">
                    Business address <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="address"
                    id="businessAddress"
                    name="businessAddress"
                  />
                  {formik.errors.businessAddress &&
                  formik.touched.businessAddress ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessAddress}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="referralCode">Referral code</label>
                  <Field type="text" id="referralCode" name="referralCode" />
                  {formik.errors.referralCode && formik.touched.referralCode ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.referralCode}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="selectCategory">
                    Business category <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    as="select"
                    id="selectCategory"
                    name="selectCategory"
                  >
                    {selectOptions.map((option) => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.value}
                        </option>
                      );
                    })}
                  </Field>
                  {formik.errors.selectCategory &&
                  formik.touched.selectCategory ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.selectCategory}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="email" id="email" name="email" />
                  {formik.errors.email && formik.touched.email ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>
              </div>

              {formik.values.selectCategory === "Others" && (
                <div className={styles.card_form_section_div}>
                  <div className={styles.card_form_section_div__control}>
                    <label htmlFor="categoryChoice">
                      Specify business category{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="categoryChoice"
                      name="categoryChoice"
                    />
                    {formik.errors.categoryChoice ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.categoryChoice}
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
              <div className={styles.card_form_section_div}>
                <div>
                  <label htmlFor="radioOption">
                    Item amount <span style={{ color: "red" }}>*</span>
                  </label>
                  <div style={{width: "300px", display: "flex"}}>
                    <Field name="radioOption" value={radioOptions.key}>
                      {({ field }) => {
                        return radioOptions.map((option) => {
                          return (
                            <div
                              className={styles.radio_input__options}
                              key={option.value}
                            >
                              <input
                                type="radio"
                                id={option.value}
                                {...field}
                                value={option.value}
                                checked={field.value === option.value}
                              />
                              <label htmlFor={option.value}>
                                {option.value}
                              </label>
                            </div>
                          );
                        });
                      }}
                    </Field>
                  </div>
                </div>
                {formik.errors.radioOption && (
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.radioOption}
                  </p>
                )}
              </div>

              <button
                style={{
                  opacity:
                    Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                }}
                disabled={
                  !formik.isValid && Object.entries(formik.errors).length === 0
                }
                type="submit"
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SilverForm1;
