import React from 'react';
import styles from '../faq/FAQs.module.scss';
import Question from '../questions/Question';

const FAQs = () => {
  return (
    <section className={styles.faqs}>
      <h3>Frequently asked questions</h3>
      <Question />
    </section>
  )
}

export default FAQs;
