import React from "react";
import { Formik, Form, Field } from "formik";
import styles from "./GoldFormStyle.module.scss";

const GoldForm1 = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.card}>
      <h2>CAC Business name registration</h2>
      <p>Complete this form to continue your registration.</p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <label htmlFor="businessNames">
                    Proposed name of business (Kindly provide at least two
                    proposed names) <span style={{ color: "red" }}>*</span>
                  </label>

                  <div className={styles.card_form_section_div__fields_control}>
                    <Field
                      id="businessNames[0]"
                      type="text"
                      name="businessNames[0]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessNames[1]"
                      type="text"
                      name="businessNames[1]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessNames[2]"
                      type="text"
                      name="businessNames[2]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                  </div>
                </div>
                {formik.errors.businessNames && formik.touched.businessNames ? (
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.businessNames}
                  </p>
                ) : null}
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <label htmlFor="businessObjects">
                    Proposed objects of business (objects means the nature of
                    business to be undertaken) {" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <div className={styles.card_form_section_div__fields_control}>
                    <Field
                      id="businessObjects[0]"
                      type="text"
                      name="businessObjects[0]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessObjects[1]"
                      type="text"
                      name="businessObjects[1]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessObjects[2]"
                      type="text"
                      name="businessObjects[2]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessObjects[3]"
                      type="text"
                      name="businessObjects[3]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                  </div>
                </div>
                {formik.errors.businessObjects &&
                formik.touched.businessObjects ? (
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.businessObjects}
                  </p>
                ) : null}
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="businessAddress">
                    Business address <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="address"
                    id="businessAddress"
                    name="businessAddress"
                  />
                  {formik.errors.businessAddress &&
                  formik.touched.businessAddress ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessAddress}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="email">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="email" id="email" name="email" />
                  {formik.errors.email && formik.touched.email ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.email}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="phoneNumber">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="tel" id="phoneNumber" name="phoneNumber" />
                  {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.phoneNumber}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="referralCode">Referral code</label>
                  <Field type="text" id="referralCode" name="referralCode" />
                  {formik.errors.referralCode && formik.touched.referralCode ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.referralCode}
                    </p>
                  ) : null}
                </div>
              </div>

              <button
                style={{
                  opacity:
                    Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                }}
                disabled={
                  !formik.isValid && Object.entries(formik.errors).length === 0
                }
                type="submit"
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default GoldForm1;
