import React, { useState } from "react";
import styles from "../preview/Preview.module.scss";

const Preview = ({ file }) => {
  const [preview, setPreview] = useState({});

  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreview(reader.result);
    };
  }

  return (
    <div className={styles.preview}>
      <img
        className={styles.preview_img}
        src={preview}
        alt=""
      />
    </div>
  );
};

export default Preview;
