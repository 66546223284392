import React, { useContext } from "react";
import { Formik, Form, Field } from "formik";
import styles from "../PlatinumForm5.module.scss";
import { DataContext } from "../../../context/Context";

const PlatinumForm5 = (props) => {
  const { Preview } = useContext(DataContext);

  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.card}>
      <h2>CAC Business name registration</h2>
      <p>
        We just need a little more information from you - can you please input
        business Proprietor's information
      </p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div_address}>
                  <div>
                    <label htmlFor="dateOfCommencement">
                      Proposed date of commencement of business{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="date"
                      id="dateOfCommencement"
                      name="dateOfCommencement"
                    />
                    {formik.errors.dateOfCommencement &&
                    formik.touched.dateOfCommencement ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.dateOfCommencement}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="ninCard">
                    Attach copies of the data page of Proprietor’s National
                    Identification Number (NIN) ID card
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="ninCard"
                    accept="image/jpeg,image/png, application/pdf"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue("ninCard", e.currentTarget.files[0])
                    }
                    type="file"
                  />
                  {formik.errors.ninCard ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.ninCard}
                    </p>
                  ) : null}
                  {formik.values.ninCard && (
                    <Preview file={formik.values.ninCard} />
                  )}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="passportPhotograph">
                    Passport photographs of each of the Proprietors
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="passportPhotograph"
                    accept="image/jpeg,image/png, application/pdf"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue(
                        "passportPhotograph",
                        e.currentTarget.files[0]
                      )
                    }
                    type="file"
                  />
                  {formik.errors.passportPhotograph ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.passportPhotograph}
                    </p>
                  ) : null}
                  {formik.values.passportPhotograph && (
                    <Preview file={formik.values.passportPhotograph} />
                  )}
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="signature">
                    Attach clearly signed signatures of the Proprietors
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="signature"
                    accept="image/jpeg,image/png, application/pdf"
                    multiple
                    onChange={(e) =>
                      formik.setFieldValue(
                        "signature",
                        e.currentTarget.files[0]
                      )
                    }
                    type="file"
                  />
                  {formik.errors.signature ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.signature}
                    </p>
                  ) : null}
                  {formik.values.signature && (
                    <Preview file={formik.values.signature} />
                  )}
                </div>
              </div>

              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PlatinumForm5;
