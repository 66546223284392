export const data = [
  {
    id: 1,
    question: "How much does it cost to use Dukka?",
    answer:
      "Dukka App is absolutely free for download on all Mobile stores. You can access the full functionalities of the Dukka App by downloading it from the Google Playstore for Android users or Appstore for iOS Users.",
  },
  {
    id: 2,
    question: "Is Launchpad a different app from the Dukka App?",
    answer:
      "Dukka LaunchPad is a valuable service with different packages for promoting business growth for Merchants in Nigeria rendering the easiest, fastest, and most secure way to launch, register and grow any business in Nigeria.",
  },
  {
    id: 3,
    question: "Do I need to keep paying for the services?",
    answer:
      "Each service is a one-time payment. Once you have selected a preferred package service and made the payment, you will not be required to make any more payments unless the entire amount for the said package was not completed at the time of selection.",
  },
  {
    id: 4,
    question: "What benefits do I get from using Dukka LaunchPad?",
    answer:
      "Many benefits exist when using Dukka LaunchPad services and these are outlined under each service.",
  },
  {
    id: 5,
    question: "How long will it take for the services to be rendered?",
    answer:
      "This varies per service package requested. However, once a member of our Team confirms your payment and all other forms within 1 hour of receipt and confirmation, you will be duly notified of the timeline for the service to be delivered.",
  },
  {
    id: 6,
    question: "How can CAC Registration help my business?",
    answer:
      "Registering your Business with the Corporate Affairs Commission(CAC) has the potential of showing that you perform a legal business in Nigeria and will be an avenue for the Government to identify you as an employer of labor impacting the economy as well as give you access to business grants where available.",
  },
  {
    id: 7,
    question: "What is Dukka Balance?",
    answer:
      "The Dukka Balance is a balance that shows all sales made on the Dukka app or Dukka POS terminal. It allow you to track transactions, sales, revenue and expenditures.",
  },
  {
    id: 8,
    question: "what is Business Name Registration?",
    answer:
      "Business name registration is the legal registration of a company. From here, Business Names can own property, employ others, incur debt and be liable for legal action just like another person.",
  },
  {
    id: 9,
    question: "What is Business LTD (Limited) registration?",
    answer:
      "A Business LTD (Limit) registration is a Business type that allows for more Shareholders and Directors to be part of a Company. A Limited liability company is a legal entity independent of its owners and has limited liability and simple operation.",
  },
  {
    id: 10,
    question:
      "What is the difference between CAC Business name registration and CAC business LTD registration?",
    answer:
      "Business Name registration is ideal for most one-man businesses while Business LTD registration is ideal where the structure supports Shareholders and Directors.",
  },
  {
    id: 11,
    question:
      "Do I need to have a physical store to create Google my business account?",
    answer:
      "Because of the peculiarity of registering for GoogleMyBusiness, You can have a profile even if your business does not have a physical address. You can also have a profile if your business is hybrid serving customers onsite or offsite to the business address (if applicable).",
  },
  {
    id: 12,
    question: "How do I choose a business name?",
    answer:
      "We advise that your business name is more than one word and the name should reflect the nature of your business and/or ends with words like Concepts/Enterprise/Ventures and so on.",
  },
  {
    id: 13,
    question:
      "What is the process of receiving my documents once my business is successfully registered?",
    answer:
      "Based on CAC regulations, hard copies of registration documents will not be printed. Once your business is registered successfully, you will get an email that will contain the E-certificate and Status Report of the application.",
  },
  {
    id: 14,
    question:
      "How long will it take for my Business registration to be completed?",
    answer:
      "Typically, once all documents are confirmed, Business registration with the Corporate Affairs Commission (CAC) will take about 10 working days. You will be duly notified of the progress of the application by email.",
  },
  {
    id: 15,
    question:
      "I need help filling out the CAC forms or have any other inquiries.",
    answer:
      "If you require support at any point, Kindly call 09155991335 or 01-8878000 or send an email to launchpad@dukka.com.",
  },
];

export const formLink = [
  {
    heading: "Emerald",
  },
  {
    heading: "Ruby",
  },
  {
    heading: "Bronze",
  },
  {
    heading: "Silver",
  },
  {
    heading: "Pearl",
  },
  {
    heading: "Gold",
  },
  {
    heading: "Platinum",
  },
  {
    heading: "Diamond",
  },
];