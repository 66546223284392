import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../plugins/axiosInstance";
import { initialValues, stepOneValidationSchema, stepTwoValidationSchema, stepThreeValidationSchema } from "../../../utils/PearlUtil";
import styles from "../FormLayout.module.scss";
import PearlForm1 from './PearlForm1';
import PearlForm2 from "./PearlForm2";
import PearlServicePayment from "../../servicepaymentlayout/PearlServicePayment";
import PaymentConfirmation from "../../../components/paymentconfirmation/PaymentConfirmation";
import { DataContext } from "../../../context/Context";

const PearlForm = () => {
  const { setError } = useContext(DataContext);
  const navigate = useNavigate();

  const [data, setData] = useState({
    initialValues,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));

    if (final) {
      finalSubmission(newData);
      return;
    }

    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <PearlForm1
      next={handleNextStep}
      data={data}
      validationSchema={stepOneValidationSchema}
    />,
    <PearlForm2
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      validationSchema={stepTwoValidationSchema}
    />,
    <PearlServicePayment
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
    />,
    <PaymentConfirmation
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      validationSchema={stepThreeValidationSchema}
    />,
  ];

  const finalSubmission = (formData) => {
    formData.selectCategory === "Others" &&
      (formData.selectCategory = formData.categoryChoice);

    let form = new FormData();

    form.append(
      "products[0]product_description",
      formData["product.description"]
    );
    form.append("products[0]product_price", formData["product.price"]);
    form.append(
      "products[0]product_image",
      formData["product.image"],
      formData["product.image"].name
    );

    if (
      formData["product.description1"] != null &&
      formData["product.description1"] !== ""
    ) {
      form.append(
        "products[1]product_description",
        formData["product.description1"]
      );
    }
    if (
      formData["product.price1"] != null &&
      formData["product.price1"] !== 0
    ) {
      form.append("products[1]product_price", formData["product.price1"]);
    }
    if (formData["product.image1"] != null) {
      form.append(
        "products[1]product_image",
        formData["product.image1"],
        formData["product.image1"].name
      );
    }
    if (
      formData["product.description2"] != null &&
      formData["product.description2"] !== ""
    ) {
      form.append(
        "products[2]product_description",
        formData["product.description2"]
      );
    }
    if (
      formData["product.price2"] != null &&
      formData["product.price2"] !== 0
    ) {
      form.append("products[2]product_price", formData["product.price2"]);
    }
    if (formData["product.image2"] != null) {
      form.append(
        "products[2]product_image",
        formData["product.image2"],
        formData["product.image2"].name
      );
    }

    if (
      formData["product.description3"] != null &&
      formData["product.description3"] !== ""
    ) {
      form.append(
        "products[3]product_description",
        formData["product.description3"]
      );
    }
    if (
      formData["product.price3"] != null &&
      formData["product.price3"] !== 0
    ) {
      form.append("products[3]product_price", formData["product.price3"]);
    }
    if (formData["product.image3"] != null) {
      form.append(
        "products[3]product_image",
        formData["product.image3"],
        formData["product.image3"].name
      );
    }

    form.append("first_name", formData.firstName);
    form.append("last_name", formData.lastName);
    form.append("phone_number", formData.phoneNumber);
    form.append("business_name", formData.businessName);
    form.append("business_address", formData.businessAddress);
    form.append(
      "special_request_or_question",
      formData.anySpecialRequestOrQuestion
    );
    form.append("email", formData.email);
    form.append("business_category", formData.selectCategory);
    // form.append("referral_code", formData.referralCode);
    if (
      formData.referralCode === "" ||
      formData.referralCode === null ||
      formData.referralCode === undefined
    ) {
      form.append("referral_code", "");
    } else {
      form.append("referral_code", formData.referralCode);
    }
    form.append("senders_name", formData.sendersName);
    form.append("date_of_payment", formData.dateOfPayment);
    form.append(
      "proof_of_payment",
      formData.uploadProofOfPayment,
      formData.uploadProofOfPayment.name
    );
    form.append("facebook_handle", formData.facebookHandle);
    form.append("subscription_type", "PEARL");

    axiosInstance
      .post("/revenue/launchpad/pearl/create/", form)
      .then((res) => {
        // console.log(res.data);
        navigate("/form/success");
      })
      .catch((error) => {
        // console.log(error);
        setError(error);
        navigate("/form/error");
      });
  };

  return (
    <div className={styles.layout}>
      {steps[currentStep]}
      <div className={styles.layout_footnote}>
        <p>
          Need help? Email us at{" "}
          <a
            style={{ textDecoration: "none", color: "black", fontWeight: 700 }}
            href="mailto:launchpad@dukka.com"
            target="_blank"
            rel="noreferrer"
          >
            launchpad@dukka.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default PearlForm;
