import React from "react";
import { Formik, Form, Field } from "formik";
import styles from "./GoldForm2.module.scss";

const GoldForm2 = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.card}>
      <h2>CAC Business name registration</h2>
      <p>
        We just need a little more information from you - can you please input
        business Proprietor's information
      </p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="firstName">
                      First name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field type="text" id="firstName" name="firstName" />
                    {formik.errors.firstName && formik.touched.firstName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.firstName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="lastName">
                      Last name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field type="text" id="lastName" name="lastName" />
                    {formik.errors.lastName && formik.touched.lastName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.lastName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="otherName">Other name (Optional)</label>
                    <Field type="text" id="otherName" name="otherName" />
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div_address}>
                  <div>
                    <label htmlFor="address">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field type="address" id="address" name="address" />
                    {formik.errors.address && formik.touched.address ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.address}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="dateOfBirth">
                    Date of birth <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="date"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    className={styles.card_form_section_div__control__input}
                  />
                  {formik.errors.dateOfBirth && formik.touched.dateOfBirth ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.dateOfBirth}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="phoneNumber1">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="tel"
                    id="phoneNumber1"
                    name="phoneNumber1"
                    className={styles.card_form_section_div__control__input}
                  />
                  {formik.errors.phoneNumber1 && formik.touched.phoneNumber1 ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.phoneNumber1}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default GoldForm2;
