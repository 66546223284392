import React, { useState } from "react";
import logo from "../../assets/logo.png";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import styles from "../navbar/Navbar.module.scss";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  const showMenu = () => {
    setMenu(!menu);
    setMobileMenu(!mobileMenu);
  };

  return (
    <nav className={styles.navbar}>
      <a href="https://dukka.com/" rel="noreferrer">
        <img src={logo} alt="logo img" />
      </a>

      <div onClick={showMenu} className={styles.navbar_menu}>
        <AiOutlineMenu />
      </div>

      {mobileMenu ? (
        <>
          <div className={styles.overlay}></div>
          <div className={styles.menubar}>
            <div>
              <span onClick={showMenu}>
                <AiOutlineClose />
              </span>
            </div>
            <ul>
              <li onClick={showMenu}>
                <a href="https://dukka.com/" rel="noreferrer">
                  Home
                </a>
              </li>
              <li>
                <a href="https://dukka.com/about" rel="noreferrer">
                  About
                </a>
              </li>
              <li>
                <a href="https://dukka.com/faq" rel="noreferrer">
                  FAQ
                </a>
              </li>
              <li>
                <a href="https://dukka.com/contact" rel="noreferrer">
                  Contact
                </a>
              </li>
              <li>
                <a href="https://dukka.com/career" rel="noreferrer">
                  Career
                </a>
              </li>
              <li>
                <a href="https://dukka.com/blog" rel="noreferrer">
                  Blog
                </a>
              </li>
              <li onClick={showMenu}>
                <NavLink to="/">Launchpad</NavLink>
              </li>
            </ul>
          </div>
        </>
      ) : null}

      <ul className={styles.navbar_links}>
        <li>
          <a href="https://dukka.com/" rel="noreferrer">
            Home
          </a>
        </li>

        <li>
          <a href="https://dukka.com/about" rel="noreferrer">
            About
          </a>
        </li>
        <li>
          <a href="https://dukka.com/faq" rel="noreferrer">
            FAQ
          </a>
        </li>
        <li>
          <a href="https://dukka.com/contact" rel="noreferrer">
            Contact
          </a>
        </li>
        <li>
          <a href="https://dukka.com/career" rel="noreferrer">
            Career
          </a>
        </li>
        <li>
          <a href="https://dukka.com/blog" rel="noreferrer">
            Blog
          </a>
        </li>
        <li>
          <NavLink to="/">Launchpad</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
