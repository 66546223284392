import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import star3 from "../../assets/star3.png";
import styles from "./EmeraldRequirements.module.scss";
import { DataContext } from '../../context/Context';

const RubyRequirements = () => {
  const { formLink } = useContext(DataContext);

  return (
    <section className={styles.requirements}>
      <div className={styles.requirements_card}>
        <h4>Thank you for your interest in our service.</h4>

        <div className={styles.requirements_card__content}>
          <p>
            Before you start filling out the form, please ensure you have the
            required document for this service listed below.
          </p>

          <div className={styles.requirements_card__content__docs}>
            <p>
              <img src={star3} alt="" />

              <span>Proof of payment</span>
            </p>
            <p>
              <img src={star3} alt="" />

              <span>Business logo and banner</span>
            </p>
            <p>
              <img src={star3} alt="" />

              <span>Product images, price and description</span>
            </p>
          </div>

          <p>
            Once you confirm you have the needed documents, please click on
            “Continue” to access the form, and make a transfer to the account
            number at the end of the form.”
          </p>
        </div>

        <Link to={`/form/${formLink[1].heading}`}>
          <button>Continue</button>
        </Link>
      </div>

      <div className={styles.requirements_footnote}>
        <p>
          Need help? Email us at{" "}
          <a
            style={{ textDecoration: "none", color: "black", fontWeight: 700 }}
            href="mailto:launchpad@dukka.com"
            target="_blank"
            rel="noreferrer"
          >
            launchpad@dukka.com
          </a>
        </p>
      </div>
    </section>
  );
}

export default RubyRequirements;
