import React, { useState, useContext } from "react";
import axiosInstance from "../../../plugins/axiosInstance";
import {
  initialValues,
  stepOneValidationSchema,
  stepTwoValidationSchema,
  stepThreeValidationSchema,
  stepFourValidationSchema,
} from "../../../utils/GoldUtil";
import styles from "../FormLayout.module.scss";
import { useNavigate } from "react-router-dom";
import GoldForm1 from "../goldform/GoldForm1";
import GoldForm2 from "../goldform/GoldForm2";
import GoldForm3 from "../goldform/GoldForm3";
import GoldServicePayment from "../../servicepaymentlayout/GoldServicePayment";
import PaymentConfirmation from "../../../components/paymentconfirmation/PaymentConfirmation";
import { DataContext } from "../../../context/Context";

const GoldForm = () => {
  const { setError } = useContext(DataContext);
  const navigate = useNavigate();

  const [data, setData] = useState({
    initialValues,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));

    if (final) {
      finalSubmission(newData);
      return;
    }

    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <GoldForm1
      next={handleNextStep}
      data={data}
      validationSchema={stepOneValidationSchema}
    />,
    <GoldForm2
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      validationSchema={stepTwoValidationSchema}
    />,
    <GoldForm3
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      validationSchema={stepThreeValidationSchema}
    />,
    <GoldServicePayment
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
    />,
    <PaymentConfirmation
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
      validationSchema={stepFourValidationSchema}
    />,
  ];

  const finalSubmission = (formData) => {
    // console.log("Form submitted", formData);

    let form = new FormData();
    form.append("passport_image", formData.passportPhotograph);
    form.append("signature_image", formData.signature);
    form.append("first_name", formData.firstName);
    form.append("last_name", formData.lastName);
    form.append("other_name", formData.otherName);
    form.append("address", formData.address);
    form.append("date_of_birth", formData.dateOfBirth);
    form.append("phone_number", formData.phoneNumber);
    form.append("phone_number1", formData.phoneNumber1);
    form.append("proposed_name_of_business", formData.businessNames);
    form.append("business_address", formData.businessAddress);
    form.append("proposed_objects_of_business", formData.businessObjects);
    form.append("email", formData.email);
    form.append("date_of_payment", formData.dateOfPayment);
    // form.append("referral_code", formData.referralCode);
    if (
      formData.referralCode === "" ||
      formData.referralCode === null ||
      formData.referralCode === undefined
    ) {
      form.append("referral_code", "");
    } else {
      form.append("referral_code", formData.referralCode);
    }
    form.append("senders_name", formData.sendersName);
    form.append("proof_of_payment", formData.uploadProofOfPayment);
    form.append(
      "proposed_commencement_date_of_business",
      formData.dateOfCommencement
    );
    form.append("national_identity_card_image", formData.ninCard);
    form.append("national_identity_card_name", formData.nameOnNin);
    form.append("subscription_type", "GOLD");

    axiosInstance
      .post("/revenue/launchpad/gold/create/", form)
      .then((res) => {
        // console.log(res.data);
        navigate("/form/success");
      })
      .catch((error) => {
        // console.log(error);
        setError(error);
        navigate("/form/error");
      });
  };

  return (
    <div className={styles.layout}>
      {steps[currentStep]}
      <div className={styles.layout_footnote}>
        <p>
          Need help? Email us at{" "}
          <a
            style={{ textDecoration: "none", color: "black", fontWeight: 700 }}
            href="mailto:launchpad@dukka.com"
            target="_blank"
            rel="noreferrer"
          >
            launchpad@dukka.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default GoldForm;
