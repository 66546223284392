import React from 'react';
import { Formik, Form, Field } from "formik";
import styles from "../goldform/GoldFormStyle.module.scss";

const PlatinumForm3 = (props) => {
    const handleSubmit = (values) => {
      props.next(values);
    };

  return (
    <div className={styles.card}>
      <h2>CAC Business name registration</h2>
      <p>Complete this form to continue your registration.</p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <label htmlFor="businessNames">
                    Proposed name of business (Kindly provide at least two
                    proposed names) <span style={{ color: "red" }}>*</span>
                  </label>

                  <div className={styles.card_form_section_div__fields_control}>
                    <Field
                      id="businessNames[0]"
                      type="text"
                      name="businessNames[0]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessNames[1]"
                      type="text"
                      name="businessNames[1]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessNames[2]"
                      type="text"
                      name="businessNames[2]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                  </div>
                </div>
                {formik.errors.businessNames && formik.touched.businessNames ? (
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.businessNames}
                  </p>
                ) : null}
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <label htmlFor="businessObjects">
                    Proposed objects of business (objects means the nature of
                    business to be undertaken){" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>

                  <div className={styles.card_form_section_div__fields_control}>
                    <Field
                      id="businessObjects[0]"
                      type="text"
                      name="businessObjects[0]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessObjects[1]"
                      type="text"
                      name="businessObjects[1]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessObjects[2]"
                      type="text"
                      name="businessObjects[2]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                    <Field
                      id="businessObjects[3]"
                      type="text"
                      name="businessObjects[3]"
                      className={
                        styles.card_form_section_div__fields_control__input
                      }
                    />
                  </div>
                </div>
                {formik.errors.businessObjects &&
                formik.touched.businessObjects ? (
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.businessObjects}
                  </p>
                ) : null}
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control_full}>
                  <label htmlFor="businessAddress">
                    Business address (Must be an address in Nigeria){" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="address"
                    id="businessAddress"
                    name="businessAddress"
                  />
                  {formik.errors.businessAddress &&
                  formik.touched.businessAddress ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessAddress}
                    </p>
                  ) : null}
                </div>
                {/* <div className={styles.card_form_section_div__control}>
                  <label htmlFor="referralCode">Referral code</label>
                  <Field type="text" id="referralCode" name="referralCode" />
                  <p className={styles.card_form_section_div__control_error}>
                    {formik.errors.referralCode}
                  </p>
                </div> */}
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="phoneNumber2">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="tel" id="phoneNumber2" name="phoneNumber2" />
                  {formik.errors.phoneNumber2 && formik.touched.phoneNumber2 ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.phoneNumber2}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="businessEmail">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field type="email" id="businessEmail" name="businessEmail" />
                  {formik.errors.businessEmail &&
                  formik.touched.businessEmail ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.businessEmail}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default PlatinumForm3;
