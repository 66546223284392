import React from "react";
import styles from "../ruby/Ruby.module.scss";
import star1 from "../../assets/star1.png";
import star2 from "../../assets/star2.png";
import star3 from "../../assets/star3.png";
import img2 from "../../assets/img2.png";
import { Link } from "react-router-dom";

const Ruby = () => (
  <section className={styles.bundles}>
    <aside className={styles.bundles__right}>
      <img src={img2} alt="" />
    </aside>
    <aside className={styles.bundles__left}>
      <h3>Ruby (₦5,000)</h3>
      <div>
        <p>
          <img src={star1} alt="" />
          <span>
            Whatsapp for Business - take your business to the next level with a
            professional Whatsapp Business Profile
          </span>
        </p>
        <p>
          <img src={star2} alt="" />
          <span>
            Cross-platform advertising - grow your business across Whatsapp,
            Facebook, and Instagram
          </span>
        </p>
        <p>
          <img src={star3} alt="" />

          <span>15 minutes FREE business consulting</span>
        </p>
      </div>
      <Link to="/ruby">
        <button>Get Started</button>
      </Link>
    </aside>
  </section>
);

export default Ruby;
