import React from "react";
import styles from "../header/Header.module.scss";
import launchpad from "../../assets/launchpad.png";

const Header = () => {
  return (
    <header className={styles.header}>
      <aside className={styles.header_left}>
        <h2>
          <span>Dukka</span> Launchpad
        </h2>

        <p>
          The easiest, fastest, and most secure way for you to launch, register
          and grow your business in Nigeria.
        </p>

        <button>
          <a href="#services" rel="noreferrer">
            View Packages
            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1.5L10 10.5L19 1.5" stroke="white" />
            </svg>
          </a>
        </button>
      </aside>

      <aside className={styles.header_right}>
        <img src={launchpad} alt="Metaverse" />
      </aside>
    </header>
  );
};

export default Header;
