import React from "react";
import styles from "../bundles/Bundles.module.scss";
// import Emerald from "../emerald/Emerald";
import Ruby from "../ruby/Ruby";
// import Bronze from "../bronze/Bronze";
import Silver from "../silver/Silver";
import Pearl from "../pearl/Pearl";
import Gold from "../gold/Gold";
import Platinum from '../platinum/Platinum';
import Diamond from "../diamond/Diamond";

const Bundles = () => {
  return (
    <section className={styles.bundles}>
      {/* <Emerald /> */}
      <Ruby />
      {/* <Bronze /> */}
      <Silver />
      <Pearl />
      <Gold />
      <Platinum />
      <Diamond />
    </section>
  );
};

export default Bundles;
