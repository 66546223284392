import React from 'react';
import { Link } from 'react-router-dom';
import img7 from "../../assets/img7.png";
import star1 from "../../assets/star1.png";
import star2 from "../../assets/star2.png";
import star3 from "../../assets/star3.png";
import styles from '../emerald/Emerald.module.scss';

const Diamond = () => {
  return (
    <section className={styles.bundles}>
      <aside className={styles.bundles__left}>
        <h3>Diamond (₦50,000)</h3>
        <div>
          <p>
            <img src={star1} alt="" />

            <span>
              Whatsapp for Business - take your business to the next level with
              a professional Whatsapp Business Profile
            </span>
          </p>
          <p>
            <img src={star2} alt="" />

            <span>CAC Business Limited registration</span>
          </p>
          <p>
            <img src={star3} alt="" />

            <span>Fast processing within a few days</span>
          </p>
          <p>
            <img src={star2} alt="" />

            <span>Quickly set up Dukka Balance</span>
          </p>
          <p>
            <img src={star3} alt="" />

            <span>20 minutes FREE Business consulting</span>
          </p>
        </div>
        <Link to="/diamond">
          <button>Get Started</button>
        </Link>
      </aside>
      <aside className={styles.bundles__right}>
        <img src={img7} alt="" />
      </aside>
    </section>
  );
}

export default Diamond
