import React, { useState } from "react";
import { createContext } from "react";
import { data, formLink } from "../utils/contextUtil";
import { IntercomProvider } from "react-use-intercom";
import Preview from "../components/preview/Preview";

const INTERCOM_APP_ID = "uhqhgsqf";

export const DataContext = createContext(); //Create context and export

const Context = (props) => {
  const [error, setError] = useState({});
  // const { boot, shutdown, hide, show, update } = useIntercom();

  const values = {
    error,
    setError,
    data,
    formLink,
    Preview,
  };

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <DataContext.Provider value={values}>
        {/* This is the main provider of the context */}
        {props.children}
      </DataContext.Provider>
    </IntercomProvider>
  );
};

export default Context;
