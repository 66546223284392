import React, { useContext } from "react";
import styles from "../error/Error.module.scss";
import { DataContext } from "../../context/Context";
import { BiErrorCircle } from "react-icons/bi";

const Error = () => {
  const { error } = useContext(DataContext);

  const keys = (key, index) => {
    switch (key) {
      case "referral_code":
        return (
          <ul key={index}>
            <li>
              <span>Invalid referral code</span>
            </li>
          </ul>
        );
      case "business_logo":
        return (
          <ul key={index}>
            <li>
              <span>
                The logo file you uploaded was either not an image or a
                corrupted image.
              </span>
            </li>
          </ul>
        );
      case "proof_of_payment":
        return (
          <ul key={index}>
            <li>
              <span>
                The proof of payment you uploaded was an unsupported file
                extension
              </span>
            </li>
          </ul>
        );
      case "passport_image":
        return (
          <ul key={index}>
            <li>
              <span>
                The passport file you uploaded was either not an image or a
                corrupted image.
              </span>
            </li>
          </ul>
        );
      case "signature_image":
        return (
          <ul key={index}>
            <li>
              <span>
                The signature file you uploaded was either not an image or a
                corrupted image.
              </span>
            </li>
          </ul>
        );
      case "national_identity_card_image":
        return (
          <ul key={index}>
            <li>
              <span>
                The ID card file you uploaded was either not an image or a
                corrupted image.
              </span>
            </li>
          </ul>
        );
      default:
        return null;
    }
  };

  const loop = (key, index, element) => {
    if (index === 0) {
      for (const [key, value] of Object.entries(element)) {
        if (key === "product_image") {
          let value =
            "The product file(s) you uploaded was either not an image or a corrupted image.";
          return (
            <ul>
              <li>
                <span>{value}</span>
              </li>
            </ul>
          );
        } else {
          return (
            <ul key={index}>
              <li>
                <span>
                  {key}: {value}
                </span>
              </li>
            </ul>
          );
        }
      }
    } else {
      return null;
    }
  };

  return (
    <section className={styles.layout}>
      <div className={styles.layout_card}>
        <>
          <BiErrorCircle />
        </>
        <div>
          <h2>Oops! 😔</h2>
          {error.response ? (
            Object.keys(error.response.data).map((key, index) => {
              if (Object.keys(error.response.data).length <= 1) {
                console.log(error.response.data[key])
                return <span key={index}>{error.response.data[key]}</span>;
              } else {
                if (key === "products")
                  return error.response.data.products.map((element, index) => {
                    loop(key, index, element);
                    return (
                      <ul key={index}>
                        <li>
                          <span>{element}</span>
                        </li>
                      </ul>
                    );
                  });
                
                return keys(key, index);
              }
            })
          ) : (
            <span>{error.message}</span>
          )}
        </div>
        <p>Please try filling out the form again.</p>
        <a href="/" rel="noreferrer">
          <button
            style={{
              backgroundColor: "#E97D80",
              border: "1px solid #E97D80",
            }}
          >
            Back
          </button>
        </a>
      </div>
    </section>
  );
};

export default Error;
