import React from "react";
import { Formik, Form, Field } from "formik";
import styles from "../goldform/GoldForm2.module.scss";

const DiamondForm5 = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.card}>
      <h2>CAC Limited registration</h2>
      <p>
        <span>
            Please input the name of company secretary
        </span>
         (This is no longer a
        compulsory requirement for a private company limited by shares and can
        be skipped. If you need to choose one, it can be any of the directors or
        a third party)
      </p>
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={props.validationSchema}
      >
        {(formik) => {
          return (
            <Form className={styles.card_form_section}>
              <p
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  color: Object.entries(formik.errors).length >= 1 ? "red" : "",
                }}
              >
                <i>
                  {" "}
                  All fields marked with an asterisk (
                  <span style={{ color: "red" }}>*</span>) are required
                </i>
              </p>
              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="secretaryFirstName">
                      First name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="secretaryFirstName"
                      name="secretaryFirstName"
                    />
                    {formik.errors.secretaryFirstName &&
                    formik.touched.secretaryFirstName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.secretaryFirstName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="secretaryLastName">
                      Last name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="secretaryLastName"
                      name="secretaryLastName"
                    />
                    {formik.errors.secretaryLastName &&
                    formik.touched.secretaryLastName ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.secretaryLastName}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__fields_control}>
                    <label htmlFor="secretaryOtherName">
                      Other name (Optional)
                    </label>
                    <Field
                      type="text"
                      id="secretaryOtherName"
                      name="secretaryOtherName"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div_address}>
                  <div>
                    <label htmlFor="secretaryAddress">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="address"
                      id="secretaryAddress"
                      name="secretaryAddress"
                    />
                    {formik.errors.secretaryAddress &&
                    formik.touched.secretaryAddress ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.secretaryAddress}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__fields}>
                  <div className={styles.card_form_section_div__control}>
                    <label htmlFor="secretaryNationality">
                      Nationality <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      id="secretaryNationality"
                      name="secretaryNationality"
                    />
                    {formik.errors.secretaryNationality &&
                    formik.touched.secretaryNationality ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.secretaryNationality}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__control}>
                    <label htmlFor="secretaryDateOfBirth">
                      Date of birth <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="date"
                      id="secretaryDateOfBirth"
                      name="secretaryDateOfBirth"
                    />
                    {formik.errors.secretaryDateOfBirth &&
                    formik.touched.secretaryDateOfBirth ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.secretaryDateOfBirth}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles.card_form_section_div__control}>
                    <label htmlFor="secretaryEmail">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      type="email"
                      id="secretaryEmail"
                      name="secretaryEmail"
                    />
                    {formik.errors.secretaryEmail &&
                    formik.touched.secretaryEmail ? (
                      <p
                        className={styles.card_form_section_div__control_error}
                      >
                        {formik.errors.secretaryEmail}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={styles.card_form_section_div}>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="secretaryPhoneNumber">
                    Phone number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="tel"
                    id="secretaryPhoneNumber"
                    name="secretaryPhoneNumber"
                  />
                  {formik.errors.secretaryPhoneNumber &&
                  formik.touched.secretaryPhoneNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.secretaryPhoneNumber}
                    </p>
                  ) : null}
                </div>
                <div className={styles.card_form_section_div__control}>
                  <label htmlFor="secretaryNinNumber">
                    NIN number <span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    type="text"
                    id="secretaryNinNumber"
                    name="secretaryNinNumber"
                  />
                  {formik.errors.secretaryNinNumber &&
                  formik.touched.secretaryNinNumber ? (
                    <p className={styles.card_form_section_div__control_error}>
                      {formik.errors.secretaryNinNumber}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className={styles.card_form_section_btn}>
                <button
                  style={{
                    backgroundColor: "#E97D80",
                    border: "1px solid #E97D80",
                  }}
                  onClick={(values) => props.prev(values)}
                  type="button"
                >
                  Back
                </button>
                <button
                  style={{
                    opacity:
                      Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                  }}
                  disabled={
                    !formik.isValid &&
                    Object.entries(formik.errors).length === 0
                  }
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default DiamondForm5;
