import React, { useContext } from "react";
import styles from "../paymentconfirmation/PaymentConfirmation.module.scss";
import { Formik, Form, Field } from "formik";
import { DataContext } from "../../context/Context";

const PaymentConfirmation = (props) => {
  const { Preview } = useContext(DataContext);
  const handleSubmit = (values) => {
    props.next(values, true);
  };

  return (
    <section className={styles.layout_card}>
      <div className={styles.layout_card_div}>
        <p style={{ color: "#000000" }} className={styles.layout_card_div_p}>
          Please provide sender’s account details below{" "}
        </p>

        <Formik
          initialValues={props.data}
          validationSchema={props.validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            return (
              <Form className={styles.layout_card_div_form_section}>
                <p
                  style={{
                    width: "100%",
                    fontSize: ".9rem",
                    color:
                      Object.entries(formik.errors).length >= 1 ? "red" : "",
                  }}
                >
                  <i>
                    {" "}
                    All fields marked with an asterisk (
                    <span style={{ color: "red" }}>*</span>) are required
                  </i>
                </p>
                <div className={styles.layout_card_div_form_section__div}>
                  <div>
                    <label htmlFor="sendersName">
                      Sender's name <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field name="sendersName" id="sendersName" type="text" />
                    {formik.errors.sendersName && formik.touched.sendersName ? (
                      <p>{formik.errors.sendersName}</p>
                    ) : null}
                  </div>
                </div>
                <div className={styles.layout_card_div_form_section__div}>
                  <div>
                    <label htmlFor="dateOfPayment">
                      Date of Payment <span style={{ color: "red" }}>*</span>
                    </label>
                    <Field
                      name="dateOfPayment"
                      id="dateOfPayment"
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                    />
                    {formik.errors.dateOfPayment &&
                    formik.touched.dateOfPayment ? (
                      <p>{formik.errors.dateOfPayment}</p>
                    ) : null}
                  </div>
                </div>
                <p
                  className={styles.layout_card_div_form_section_text}
                  style={{ color: "#9D9D9D" }}
                ></p>
                <hr />
                <div className={styles.layout_card_div_form_section__div}>
                  <div>
                    <label htmlFor="uploadProofOfPayment">
                      Upload proof of payment{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      name="uploadProofOfPayment"
                      accept="image/jpeg,image/png, application/pdf"
                      multiple
                      onChange={(e) =>
                        formik.setFieldValue(
                          "uploadProofOfPayment",
                          e.currentTarget.files[0]
                        )
                      }
                      type="file"
                    />
                    {formik.errors.uploadProofOfPayment ? (
                      <p>{formik.errors.uploadProofOfPayment}</p>
                    ) : null}
                    {formik.values.uploadProofOfPayment && (
                      <Preview file={formik.values.uploadProofOfPayment} />
                    )}
                  </div>
                </div>
                <div className={styles.layout_card_div_form_section_btn}>
                  <button
                    style={{
                      backgroundColor: "#E97D80",
                      border: "1px solid #E97D80",
                    }}
                    onClick={(values) => props.prev(values)}
                    type="button"
                  >
                    Back
                  </button>
                  <button
                    style={{
                      opacity:
                        Object.entries(formik.errors).length >= 1 ? "0.5" : "1",
                    }}
                    disabled={
                      !formik.isValid &&
                      Object.entries(formik.errors).length === 0
                    }
                    type="submit"
                  >
                    Proceed
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
};

export default PaymentConfirmation;
