import React from "react";
import star2 from "../../assets/star2.png";
import star3 from "../../assets/star3.png";
import img6 from "../../assets/img6.png";
import styles from "../pearl/Pearl.module.scss";
import { Link } from "react-router-dom";

const Gold = () => {
  return (
    <section className={styles.bundles}>
      <aside className={styles.bundles__left}>
        <h3>Gold (₦26,500)</h3>
        <div>
          <p>
            <img src={star2} alt="" />

            <span>CAC Business Name registration</span>
          </p>
          <p>
            <img src={star3} alt="" />

            <span>Fast processing within a few days</span>
          </p>
        </div>
        <Link to="/gold">
          <button>Get Started</button>
        </Link>
      </aside>
      <aside className={styles.bundles__right}>
        <img src={img6} alt="" />
      </aside>
    </section>
  );
};

export default Gold;
