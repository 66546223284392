import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import styles from '../layout/Layout.module.scss';

const Layout = () => {
  return (
    <main className={styles.container}>
      <Navbar />

      <>
        <Outlet />
      </>

      <Footer />
    </main>
  );
};

export default Layout;
