import React from "react";
import styles from "../servicepaymentlayout/RubyServicePayment.module.scss";

const GoldServicePayment = (props) => {
  const handleConfirmation = (values) => {
    props.next(values);
  };

  return (
    <div className={styles.layout_card}>
      <div className={styles.layout_card__div}>
        <h5>
          To complete your registration you are required to make a payment of
          <span>₦26,500</span> for this service. If you would like to proceed
          with the payment and access the form, make a transfer to the account
          number below
        </h5>
        <h1>5401580638</h1>
        <h2>Dukka Limited</h2>
        <h3>Providus Bank</h3>
      </div>
      <div className={styles.layout_card__btn}>
        <button
          style={{
            backgroundColor: "#E97D80",
            border: "1px solid #E97D80",
          }}
          onClick={({ values }) => props.prev(values)}
          type="button"
        >
          Back
        </button>
        <button type="submit" onClick={handleConfirmation}>
          I have sent it
        </button>
      </div>
    </div>
  );
};

export default GoldServicePayment;
