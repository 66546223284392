import React from 'react';
import FAQs from '../faq/FAQs';
import Services from '../services/Services';
import Header from '../header/Header';

const Home = () => {

  return (
    <div>
      <Header />
      <Services />
      <FAQs />
    </div>
  )
}

export default Home;
