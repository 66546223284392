import React from "react";
import styles from "../pearl/Pearl.module.scss";
import star1 from "../../assets/star1.png";
import star2 from "../../assets/star2.png";
import img5 from "../../assets/img5.png";
import { Link } from "react-router-dom";

const Pearl = () => {
  return (
    <section className={styles.bundles}>
      <aside className={styles.bundles__right}>
        <img src={img5} alt="" />
      </aside>
      <aside className={styles.bundles__left}>
        <h3>Pearl (₦20,000)</h3>
        <div>
          <p>
            <img src={star1} alt="" />

            <span>Dukka TV Business Spotlight</span>
          </p>
          <p>
            <img src={star2} alt="" />

            <span>20 minutes FREE business consulting</span>
          </p>
        </div>
        <Link to="/pearl">
          <button>Get Started</button>
        </Link>
      </aside>
      
    </section>
  );
};

export default Pearl;
